import React, {
    PropsWithChildren,
    ReactElement,
    useState,
    useRef,
} from "react";
import useWindowWidth, {
    heroDesktopMinWidth,
} from "../../hooks/useWindowWidth";
import Hero from "../../components/shared/Hero";
import Modal from "../../components/shared/Modal";
import ContentGrid from "./ContentGrid";
import ContentNav from "./ContentNav";
import ContentNavMobile from "./ContentNavMobile";
import GradientImageBanner from "../../components/Widgets/GradientImageBanner";
import styles from "./Content.module.scss";
import useIsBrowser from "../../hooks/useIsBrowser";
import Helmet from "../../components/Helmet";
import { IFeaturedContent } from "./Interfaces/FeaturedContentInterfaces";
import { ContentItem } from "./Classes/contentItemClass";
import WarningComponent from "../../components/shared/FallbackWarnComponent";
import { getSeoDescription, getSeoKeywords, getSeoTitle } from "../../shared/helpers";

//#region functions
const getYoutubeVideoUrl = (url: string) => {
    let video_id = url.split("v=")[1];
    const ampersandPosition = video_id.indexOf("&");

    if (ampersandPosition != -1) {
        video_id = video_id.substring(0, ampersandPosition);
    }

    const src = `https://www.youtube.com/embed/${video_id}?origin=${process.env.GATSBY_SITE_URL}`;

    return <iframe src={src} className={styles.video} width="100%" />;
};

const getVimeoVideoId = (url: string) => {
    const regExp = /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/;
    const match = url.match(regExp);

    return match && match[5];
};
const getVimeoVideoUrl = (url: string) => {
    const src = `https://player.vimeo.com/video/${getVimeoVideoId(url)}`;

    return <iframe src={src} className={styles.video} width="100%" />;
};

const getVideoSrc = (url: string | undefined) => {
    let videoSrc: JSX.Element;
    if (typeof url === "undefined") return;
    // for now we only handle youtube and vimeo options
    if (url.includes("youtube")) {
        videoSrc = getYoutubeVideoUrl(url);
    } else if (url.includes("vimeo")) {
        videoSrc = getVimeoVideoUrl(url);
    } else {
        videoSrc = (
            <video className={styles.video} width="100%" controls>
                <source src={url}></source>
            </video>
        );
    }

    return videoSrc;
};

//#endregion

const MediaPage = (props: PropsWithChildren<any>): ReactElement => {
    const {
        pageContext: { data, relations },
    } = props;
    const { try_us, categories } = relations;
    const bannerProps = {
        banner_title: try_us.dataRaw.title,
        banner_text: try_us.dataRaw.subtitle,
        banner_background: try_us.dataRaw.background_image,
        banner_cta_text: try_us.dataRaw.cta_text,
        banner_cta_url: try_us.dataRaw.cta_url,
        banner_gradient_color: try_us.dataRaw.grandient_color,
        banner_text_color: try_us.dataRaw.text_color,
    };

    const [currentCategory, setCurrentCategory] = useState(categories[0]);
    const [showModal, setShowModal] = useState(false);

    const currentItem = useRef<ContentItem>();

    // const categoryTitle = currentCategory.dataRaw.title[0].text;

    const onItemSelected = (item: IFeaturedContent) => {
        currentItem.current = new ContentItem(item);
        setShowModal(true);
    };

    const renderModalContent = () => {
        switch (currentCategory.dataRaw.key_page) {
            case "image":
                return (
                    <img
                        src={currentItem.current?.Image.url}
                        alt={currentItem.current?.Image.alt}
                        className={styles.imageModalContent}
                    />
                );
            case "video":
                if (currentItem.current?.ExternalVideo.url) {
                    return getVideoSrc(currentItem.current.ExternalVideo.url);
                } else if (currentItem.current?.UploadedVideo.url) {
                    return getVideoSrc(currentItem.current.UploadedVideo.url);
                }
        }
    };

    const isDesktop = useWindowWidth(heroDesktopMinWidth);
    const { isBrowser, key } = useIsBrowser();

    if (!isBrowser) return null as any;

    return (
        <>
            <Helmet
                title={getSeoTitle(data, "Content")}
                description={getSeoDescription(data, "Content page")}
                keywords={getSeoKeywords(data, "")} 
            />

            <Hero data={{ image: data.hero_image, title: data.page_title }} />

            <main className={styles.mainWrapper}>
                {isDesktop ? (
                    categories.length > 0 ? (
                        <ContentNav
                            content={categories}
                            onNavItemSelected={setCurrentCategory}
                        />
                    ) : (
                        <WarningComponent
                            template={`Content page`}
                            message={"Content nav (categories)"}
                        />
                    )
                ) : categories.length > 0 ? (
                    <ContentNavMobile
                        content={categories}
                        onNavItemSelected={setCurrentCategory}
                    />
                ) : (
                    <WarningComponent
                        template={`Content page`}
                        message={"Content nav (categories)"}
                    />
                )}

                <div className={styles.mediaContentWrapper}>

                    {currentCategory.dataRaw.featured_content.length > 0 ? (
                        <section
                            className={`${styles.mediaSectionContainer} ${styles.featured}`}
                        >
                            <div className={`${styles.mediaSection} mainBox`}>
                                <h2 className={styles.titleFeatured}>Featured {currentCategory.dataRaw.category_title[0]?.text}</h2>
                                <ContentGrid
                                    key={currentCategory.dataRaw.key_page}
                                    items={
                                        currentCategory.dataRaw.featured_content
                                    }
                                    featured={true}
                                    type={currentCategory.dataRaw.key_page}
                                    onItemSelected={onItemSelected}
                                />
                            </div>
                        </section>
                    ) : (
                        <WarningComponent
                            template={`Content page`}
                            message={"Featured content"}
                        />
                    )}

                    {currentCategory.dataRaw.body.length > 0 ? (
                        <section className={styles.mediaSectionContainer}>
                            <div className={`${styles.mediaSection} mainBox`}>
                                <ContentGrid
                                    key={currentCategory.dataRaw.key_page}
                                    items={currentCategory.dataRaw.body}
                                    onItemSelected={onItemSelected}
                                    type={currentCategory.dataRaw.key_page}
                                />
                            </div>
                        </section>
                    ) : (
                        <WarningComponent
                            template={`Content page`}
                            message={"Main content"}
                        />
                    )}

                </div>

            </main>

            {showModal && (
                <Modal
                    styles={{ borderRadius: 6, closeColor: "var(--theme-colorAccent)" }}
                    handleClose={() => setShowModal(false)}
                    type={currentCategory.dataRaw.key_page}
                >
                    {renderModalContent()}
                </Modal>
            )}

            {try_us.dataRaw ? (
                <GradientImageBanner data={{ ...bannerProps }} />
            ) : (
                <WarningComponent
                    template={`Content page`}
                    message={"Try us widget"}
                />
            )}
        </>
    );
};

export default MediaPage;
