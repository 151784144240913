import { ILink } from "../Interfaces/GeneralInterfaces";
import { IImage } from "../Interfaces/ImageInterfaces";

export class ContentItem {
    public ExternalVideo: ILink;
    public Image: IImage;
    public SpaceOccupation: string;
    public UploadedVideo: ILink;

    constructor(item: any){
        const isFeatured = Object.keys(item)[0].includes("featured");
        this.ExternalVideo = isFeatured ? item.featured_external_video : item.content_external_video;
        this.Image = isFeatured ? item.featured_image : item.content_image;
        this.SpaceOccupation = isFeatured ? item.featured_space_occupation : null;
        this.UploadedVideo = isFeatured ? item.featured_uploaded_video : item.content_uploaded_video;
    }
}
