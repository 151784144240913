import axios from "axios";

const API_URL = "https://api.vimeo.com";

class VimeoService {
    token = "f4547fe262322382bca3baabc143dba9";
    app = null;

    // async authorize()
    // {
    //     const token = btoa(`${appId}:${clientSecret}`);
    //     const response = await axios({
    //         url: `${API_URL}/oauth/authorize/client`,
    //         method: "POST",
    //         data: JSON.stringify({
    //             "grant_type": "client_credentials",
    //             "scope": "public"
    //         }),
    //         headers: {
    //             "Authorization": "basic " + token,
    //             "Content-Type": "application/json",
    //             "Accept": "application/vnd.vimeo.*+json;version=3.4"
    //         }
    //     });

    //     this.token = response.data.access_token;
    //     this.app = response.data.app;

    //     return Promise.resolve();
    // }

    async getVideoThumbnailUrl(videoId: string)
    {
        const response = await axios.get(`https://api.vimeo.com/videos/${videoId}/pictures`, {
            headers: {
                "Authorization": "bearer " + this.token
            }
        });

        return response.data;
    }
}

export default new VimeoService();