import React, { useState, useEffect, useRef, PropsWithChildren } from "react";
import { useEventListener } from "../../../hooks/UseEventListener";
import useIsBrowser from "../../../hooks/useIsBrowser";
import { chevron } from "../../../shared/icons";
import { IContent_category } from "../Interfaces/GeneralInterfaces";
import styles from "./ContentNavMobile.module.scss";

interface INavigation {
    content: Array<IContent_category>,
    onNavItemSelected: any; // set state 
}

const ContentNavMobile = (props: PropsWithChildren<INavigation>) => {

    const { content, onNavItemSelected } = props;

    const mainWrapper = useRef<HTMLDivElement>(null as any);
    const [showOptions, setShowOptions] = useState(false);
    const [listHeight, setListHeight] = useState<number>(null as any);
    const currentCategoryRef = useRef(content[0]);
    const { isBrowser } = useIsBrowser();


    const handleCategorySelection = (category: IContent_category) => {
        currentCategoryRef.current = category;
        setShowOptions(false);
        onNavItemSelected(category);
    };

    const calculateListHeight = () => {
        if (!mainWrapper?.current) return;

        const { top, height } = mainWrapper.current.getBoundingClientRect();
        setListHeight(window.innerHeight - (top + height));
    };

    if (isBrowser) {
        if (showOptions) {
            document.body.classList.add(styles.noOverflow);
        } else {
            document.body.classList.remove(styles.noOverflow);
        }
    }

    useEffect(calculateListHeight, [showOptions]);
    useEventListener("resize", calculateListHeight);

    return (
        <nav
            ref={mainWrapper}
            className={styles.categoriesNav}
            style={{
                ["--calculatedViewportHeight" as string]: `${listHeight}px`,
            }}
        >
            <button
                className={styles.categorySelect}
                onClick={() => setShowOptions(!showOptions)}
            >
                <span>{currentCategoryRef.current.dataRaw.category_title[0]?.text}</span>

                <span className={styles.arrow}>
                    {!showOptions ? chevron.down : chevron.top}
                </span>
            </button>

            {showOptions && (
                <div className={styles.categoryList}>
                    {content.map((category: IContent_category) => (
                        <div
                            key={category.id}
                            onClick={() => handleCategorySelection(category)}
                        >
                            {category.dataRaw.category_title[0]?.text}
                        </div>
                    ))}
                </div>
            )}
        </nav>
    );
};

export default ContentNavMobile;
