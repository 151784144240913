import React, { useEffect, useState, Fragment, PropsWithChildren, ReactElement } from "react";
import { arrows, playIcon } from "../../../shared/icons";
import VimeoService from "../../../services/VimeoService";
import styles from "./ContentGrid.module.scss";
import { ContentItem } from "../Classes/contentItemClass";
import { IBody } from "../Interfaces/BodyInterfaces";
//#region functions
const getSpaceOccupation = (spaceOccupation: string) => {
    switch (spaceOccupation) {
        case "2 rows":
            return styles.expandRows;
        case "2 cols":
            return styles.expandCols;
        default:
            return "";
    }
};

const getVimeoVideoId = (url: string) => {
    const regExp = /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/;
    const match = url.match(regExp);

    return match && match[5];
}
//#endregion

interface IMediaItemProps {
    item: ContentItem,
    handlePointerDown: any,
    type: string
}

const MediaItem = (props: PropsWithChildren<IMediaItemProps>): ReactElement => {
    const { item, handlePointerDown, type } = props;


    const contentItem = new ContentItem(item);

    const [thumbnailImg, setThumbnailImg] = useState(null);

    let src: string | null = "";
    let alt: string | null = "";
    let icon: any = null;

    if (type === "image") {
        src = contentItem.Image.thumbnail?.url;
        alt = contentItem.Image.thumbnail?.alt;
    } else if (type === "video") {
        src = contentItem.Image.thumbnail.url ? contentItem.Image.thumbnail.url : thumbnailImg;
        alt = contentItem.Image.thumbnail.alt ? contentItem.Image.thumbnail.alt : "Logo";
        icon = <div className={styles.circle}>{arrows.right}</div>;
    }

    useEffect(() => {

        const getThumbnail = async (videoId: any) => {
            const thumb = await VimeoService.getVideoThumbnailUrl(videoId);
            setThumbnailImg(thumb.data[0].sizes[3].link);
        }

        if (!contentItem.Image.thumbnail?.url && contentItem.ExternalVideo.link_type === "Web" && contentItem.ExternalVideo.url?.includes("vimeo")) {
            const videoId = getVimeoVideoId(contentItem.ExternalVideo.url);
            getThumbnail(videoId);
        }

    }, []);

    return (
        <div
            className={`${styles.gridItem} ${getSpaceOccupation(
                contentItem.SpaceOccupation
            )} ${type === "video" ? styles.videoPlay : ""} ${src !== null ? "" : styles.fallbackThumbnailWrapper} `}
            onClick={() => handlePointerDown(item)}
        >
            {playIcon}
            <img src={src !== null ? src : "/assets/image/ContentLogo.png" } className={src !== null ? "" : styles.fallbackThumbnail}></img>
        </div> 
    );
};


interface IContentGridProps {
    items: Array<ContentItem>,
    featured?: boolean,
    type: string,
    onItemSelected: any
}

const ContentGrid = (props: PropsWithChildren<IContentGridProps>): ReactElement => {
    const { onItemSelected, type, items } = props;

    return (
        <>
            {!props.featured ? (
                <div>
                    {items.map(
                        (item, i:number) => {
                            const bodyItem = item as unknown as IBody;
                            return (
                                <React.Fragment key={i} >
                                    <h2>{bodyItem.primary.sub_category_title[0]?.text}</h2>
                                    <div className={styles.mediaGrid}>
                                        {bodyItem.items.map(
                                            (mediaItem: ContentItem, i:number) => (
                                                <MediaItem
                                                    key={i}
                                                    item={mediaItem}
                                                    handlePointerDown={onItemSelected}
                                                    {...{ type }}
                                                />
                                            )
                                        )}
                                    </div>
                                </React.Fragment>
                            );
                        }
                    )}
                </div>
            ) : (
                <div
                    className={`${styles.mediaGrid} ${props.featured ? styles.featured : styles.regular
                        }`}
                >
                    {items.map((mediaItem: ContentItem, i: number) => (
                        <MediaItem
                            key={i}
                            item={mediaItem}
                            handlePointerDown={onItemSelected}
                            {...{ type }}
                        />
                    ))}
                </div>
            )}
        </>
    );
};

export default ContentGrid;
