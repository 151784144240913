import React, { useState, useRef, useEffect, PropsWithChildren, ReactElement, SetStateAction } from "react";
import { IContent_category } from "../Interfaces/GeneralInterfaces";
import styles from "./ContentNav.module.scss";

interface INavigation{
    content : Array<IContent_category>,
    onNavItemSelected : any ; // set state 
}

export const ContentNav = (props: PropsWithChildren<INavigation>): ReactElement => {
    const { content, onNavItemSelected } = props;
    const [current, setCurrent] = useState(0);
    const tabNavigation = useRef<HTMLDivElement>(null as any);
    const currentTab = useRef<HTMLButtonElement>(null as any);

    const handleTabNavigation = (category: object, current: number) => {
        setCurrent(current);
        onNavItemSelected(category);
    };

    useEffect(() => {
        if (!tabNavigation?.current || !currentTab?.current) return;

        tabNavigation.current.style.setProperty("--trackWidth", `${currentTab.current.clientWidth}px`);
        tabNavigation.current.style.setProperty("--trackLeft", `${currentTab.current.offsetLeft}px`);
    }, [current]);

    return (
        <nav className={styles.tabNavigationWrapper}>
            <div ref={tabNavigation} className={styles.tabNavigation}>
                {content.map((x: IContent_category, i: number) => (
                    <button
                        ref={current === i ? currentTab : undefined}
                        key={x.id}
                        className={styles.tabNavigationItem}
                        onClick={() => handleTabNavigation(x, i)}
                    >
                        {x.dataRaw.category_title[0].text}
                    </button>
                ))}

                <div className={styles.navTrack} />
            </div>
        </nav>
    );
};

export default ContentNav;
