import React, { PropsWithChildren, ReactElement } from "react";
import styles from "./Hero.module.scss";
import useIsBrowser from "../../../hooks/useIsBrowser"

interface HeroProps
{
    data: any;
}

const Hero = (props: PropsWithChildren<HeroProps>): ReactElement =>
{
    const {
        image,
        title
    } = props.data;

    const { isBrowser, key } = useIsBrowser();

    if (!isBrowser) return null;

    return (
        <section className={styles.heroContainer} key={key}>
            {image && <img src={image.url} className={styles.imageContainer} alt={image?.alt} />}
                {title && <h1>{title[0]?.text}</h1>}
        </section>
    );
};

export default Hero;
